<template>
    <div class="background">
        <!-- Dialog copy -->
        <div class="confirm-popup" :style="{transform: this.popUpConfirmState ? 'translateY(0)' : 'translateY(-100%)'}">
            <span>URL copié !</span>
        </div>

        <div class="share_dialog">
            <div class="header">
                <span>{{ baseName }}</span>
                <button @click="this.$store.commit('closeSharePopup')">
                    <i>close</i>
                </button>
            </div>
            <div class="paragraph">
                <input type="text" id="url_text" v-model="link" readonly>
                <button @click="copyText();">Copier</button>
            </div>
            <p class="infos">Vous pouvez partager les infos de votre base via le lien ci-dessus.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShareDialog",

    data() {
      return {
          popUpConfirmState: false
      }
    },

    props: {
        link: {
            required: true,
        },
        baseName: {
            required: true
        }
    },

    methods: {
        copyText() {
            navigator.clipboard.writeText(this.link);

            this.$store.commit('toast', {
                type: "success",
                message: `URL copié`,
                duration: 2000
            })
        },
    }
}
</script>

<style scoped>
.background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--black-overlay);
    z-index: 3000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-popup {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: var(--green1);
    color: var(--white0);
    transition: var(--transition);
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.share_dialog {
    background-color: var(--white0);
    border-radius: var(--border-radius);
    padding: 16px;
    margin: 16px;
    width: auto;
    height: auto;
}

.header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.header span {
    font-size: 2em;
    margin: 0;
}

.paragraph {
    margin: 32px 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.paragraph input {
    flex: 1;
    margin-right: 16px;
    font-size: 1.2em;
    outline: none;
}

.paragraph input:focus {
    box-shadow: var(--orange0) 0 0 0 2px;
}

.paragraph button {
    width: auto;
    font-size: 1em;
    border-radius: var(--border-radius);
    border: none;
    outline: none;
    padding: 0 8px;
    background-color: var(--orange1);
    color: var(--white0)
}

.paragraph button:hover {
    cursor: pointer;
    background-color: var(--orange0)
}

p.infos {
    margin: 0;
}

</style>