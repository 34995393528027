<template>
    <div id="mapid">
        <MapControls
                @zoomin="this.zoomin"
                @zoomout="this.zoomout"
                :max-zoom="this.controls.maxZoom"
                :min-zoom="this.controls.minZoom" />

        <div :style="{
            transform: `translate(calc(${this.popup.position.x}px - 50%), calc(${this.popup.position.y}px - 100%))`,
            opacity: this.popup.isVisible ? '1' : '0'}"
            class="popup">{{this.popup.content}}
        </div>
    </div>
</template>

<script>

import L from "leaflet";
import {toRaw} from "vue";

import MapControls from "@/components/Map/MapControls";

L.DomUtil.TRANSITION = false

import "/node_modules/leaflet.markercluster/dist/leaflet.markercluster-src"

//Gestion des coordonnées
let yx = L.latLng
let xy = function (x, y) {
    if (L.Util.isArray(x)) { // When doing xy([x, y]);
        return yx(x[1], x[0])
    }
    return yx(y, x) // When doing xy(x, y);
}

export default {
    name: "Map",

    components: {
        MapControls,
    },

    watch: {

        allUsers() {
            let listeBasesString = []
            this.mapSettings.bases.forEach(userId => {
                listeBasesString.push(userId)
            })

            if(toRaw(this.mapSettings.bases).length !== this.allUsers.length) {
                this.allUsers.forEach(user => {
                    if(listeBasesString.indexOf(user.id) === -1) {
                        this.setBase(user)
                    }
                })
            }


        },

        activeUser() {
            if (Object.entries(this.activeUser).length > 0) {
                this.$store.commit('setSidePanelInfos', this.activeUser.id)
                this.$store.dispatch('setSidePanelInfos', this.activeUser.id)
                this.$store.commit('openSidePanel')
                this.mapSettings.map.setView(xy(this.activeUser.base.position_y, this.activeUser.base.position_x), this.mapSettings.map.getMaxZoom())
            }
        }
    },

    data() {
        return {
            mapSettings: {
                map: {},
                cluster: {},
                bases: [],
                markers: [],
                newMarkers: [],
            },

            popup: {
                isVisible: false,
                content: "",
                position: {
                    x: 0,
                    y: 0,
                }
            },

            controls: {
                maxZoom: false,
                minZoom: true,
            },

            refreshInterval: null,
        }
    },

    computed: {
        allUsers() {
            return toRaw(this.$store.state.allUsers)
        },

        activeUser() {
            return JSON.parse(JSON.stringify(this.$store.state.activeUser))
        }

    },

    methods: {

        zoomin() {
            if(this.mapSettings.map.getZoom() < this.mapSettings.map.getMaxZoom()) {
                this.mapSettings.map.setZoom(this.mapSettings.map.getZoom() + 1)
            }
        },

        zoomout() {
            if(this.mapSettings.map.getZoom() > this.mapSettings.map.getMinZoom()) {
                this.mapSettings.map.setZoom(this.mapSettings.map.getZoom() - 1)
            }
        },

        getBaseSize(value) {
            let inMin = 3;
            let inMax = 6;
            let outMin = 30;
            let outMax = 150;

            return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
        },

        createMap() {
            //Initialisation de la map
            this.mapSettings.map = L.map('mapid', {
                minZoom: 3,
                maxZoom: 6,
                maxBoundsViscosity: 1,
                crs: L.CRS.EPSG3857,
            })

            //Paramètrage de la taille de la map
            toRaw(this.mapSettings.map).setMaxBounds([xy(0, 0), xy(300, 80)])

            //Création du layer (fond) de la map
            L.GridLayer.DebugCoords = L.GridLayer.extend({
                createTile: function (coords, done) {

                    //création d'une tuile (style)
                    let tile = document.createElement('div');
                    //tile.innerHTML = [coords.z, coords.x, coords.y].join(' > ');
                    //tile.style.outline = '1px solid red';
                    tile.style.backgroundImage = `url("/map/${coords.z}/${coords.x}/${coords.y}/map.png")`
                    tile.style.backgroundPosition = "center"
                    tile.style.backgroundSize = "cover"

                    setTimeout(function () {
                        done(null, tile); // Syntax is 'done(error, tile)'
                    }, 500 + Math.random() * 1500);

                    return tile;
                }
            });

            L.gridLayer.debugCoords = function (opts) {
                return new L.GridLayer.DebugCoords({opts});
            };

            let debugCoordsGrid = L.gridLayer.debugCoords({
                noWrap: false
            });

            toRaw(this.mapSettings.map).addLayer(debugCoordsGrid);

            let that = this
            toRaw(this.mapSettings.map).on('zoomend', function() {
                that.controls.maxZoom = toRaw(that.mapSettings.map).getZoom() === toRaw(that.mapSettings.map).getMaxZoom();
                that.controls.minZoom = toRaw(that.mapSettings.map).getZoom() === toRaw(that.mapSettings.map).getMinZoom();

                document.documentElement.style.setProperty('--marker-size', `${that.getBaseSize(that.mapSettings.map.getZoom())}px`)
            })

            this.createMarkers()
            toRaw(this.mapSettings.map).setView(xy(300 / 2, 80 / 2), 3)
        },

        setBase(user) {

            let userBase = false
            if(window.localStorage.getItem('user') !== null) {
                userBase = user.id === JSON.parse(window.localStorage.getItem('user')).user.id
            }

            let that = this
            let newMarker = L.marker(
                [user.base.position_x, user.base.position_y],
                {
                    icon: L.divIcon({
                        className: `icon-base-${user.base.img_base} ${userBase ? 'userBase' : ''} ${user.base.date_fin !== null ? 'dead' : ''}`,
                        iconSize: null,
                        iconAnchor: [10, -10],
                    }),
                    idUser: user.id,
                    nom: user.base.nom
                }
            )
                .on('mouseover', function(ev) {
                that.popup.isVisible = true;
                that.popup.content = ev.target.options.nom;
                that.popup.position.x = ev.containerPoint.x;
                that.popup.position.y = ev.containerPoint.y;
            })
            .on('mouseout', function() {
                that.popup.isVisible = false
            })
            .on('click', function(ev) {
                that.popup.isVisible = false
                that.$store.commit('setActiveUser', ev.target.options.idUser)
            })

            this.mapSettings.bases.push(user.id)
            this.mapSettings.cluster.addLayer(newMarker)
        },

        createMarkers() {
            //Paramétrage des clusters
            this.mapSettings.cluster = L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    return L.divIcon({html: '<div class="cluster">' + cluster.getChildCount() + '</b>'});
                },
                showCoverageOnHover: false,
                removeOutsideVisibleBounds: true,
                maxClusterRadius: 50,
            });

            toRaw(this.mapSettings.map).addLayer(this.mapSettings.cluster)
        },
    },

    mounted() {
        this.createMap()

        let that = this
        this.$store.dispatch('getUsersInfos').then(function(){

        })


        this.refreshInterval = setInterval(function() {
            that.$store.dispatch('getUsersInfos')
        }, 10000)
    },

    unmounted() {
        clearInterval(this.refreshInterval)
    }
}
</script>

<style scoped>
#mapid {
    z-index: 10;
    height: 100vh;
    background: var(--map);
    cursor: move;
}

.popup {
    position: absolute;
    background: var(--white0);
    opacity: 0;
    z-index: 90000;
    padding: 8px;
    border-radius: var(--border-radius);
    pointer-events: none;
    transition: opacity 200ms ease-in-out;
    font-weight: bold;
    font-size: 1.2em;
}
</style>