<template>
    <div class="search">
        <div class="search-input"
             :style="{borderRadius: this.searchResults.length === 0 && this.noSearchResults === false ? 'var(--border-radius)' : 'var(--border-radius) var(--border-radius) 0 0',}">
            <div class="search-icon">
                <i>search</i>
            </div>

            <input id="searchbar"
                   v-model="searchTerm"

                   @keydown.down="nextIndex"
                   @keydown.up="prevIndex"
                   @keydown.enter="displayPlayerEnter(this.searchResults[this.userIndex])"
                   type="text"
                   name="search"
                   placeholder="Chercher un joueur">
            <div class="autocomplete" v-if="!this.userHover">
                {{this.searchResults.length > 0 ? this.searchResults[this.userIndex].username : this.searchTerm.length > 0 ? "Aucun résultats" : ""}}
            </div>
            <button class="close-icon" @click="clearSearch">
                <i>close</i>
            </button>
        </div>

        <ul class="list" v-if="!this.$store.state.playersCharging">
            <li v-for="result in this.searchResults"
                @click="goToBase(result.id)"
                :class="{selected: this.searchResults[this.userIndex] === result}"
            >
                <span>{{ result.username }}</span>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: "searchbar",

    data() {
        return {
            searchTerm: "",
            noSearchResults: false,
            userIndex: 0,
            userHover: false,
        }
    },

    watch: {
        searchTerm() {
            this.userIndex = 0;
            this.userHover = false;
        },

        userIndex() {
            var topPos = document.querySelector('.selected').offsetTop
            document.querySelector('.list').scrollTop = topPos - (40 * 4);
        }
    },

    computed: {

        searchResults() {
            let allUsers = JSON.parse(JSON.stringify(this.$store.state.allUsers))
            return this.tabObjsFiltreTexte(allUsers, 'username', this.searchTerm)
        },
    },

    methods: {

        nextIndex() {
            if(this.userIndex < this.searchResults.length -1)
                this.userIndex++
        },

        prevIndex() {
            if(this.userIndex > 0)
                this.userIndex--
        },

        displayPlayerEnter(user) {
            if(this.searchResults.length > 0) {
                this.goToBase(user.id)
            }
        },

        goToBase(idUser) {
            this.clearSearch()
            this.$store.commit('setActiveUser', idUser)
        },

        clearSearch() {
            this.searchTerm = "";
            this.searchResults = [];
            this.noSearchResults = false;
        },



        tabObjsFiltreTexte (tableau, propriete, chaine) {
            const res = [...tableau]
            if (!chaine) { return [] }

            let filteredRes = res.filter((ligne) => {
                return ligne[propriete].toLowerCase().normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(chaine.toLowerCase().normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, ''))
            })

            return filteredRes.sort((a, b) => {
                let fa = a.username.toUpperCase(),
                    fb = b.username.toUpperCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        }
    },
}
</script>

<style scoped>
.search {
    width: 400px;
    box-shadow: var(--shadow);
}

.search-input {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: var(--white0);
    overflow: hidden;
}

.autocomplete {
    position: absolute;
    height: 40px;
    right: 40px;
    width: auto;
    padding-left: 8px;
    background-color: var(--white0);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--black-overlay);
}

.close-icon {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.search-icon i, .close-icon i {
    font-size: 1.75em;
    color: var(--black2)
}

.search .search-input input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 1em;
    color: var(--black2);
    text-overflow: ellipsis;
}

.search .list {
    list-style-type: none;
    background-color: var(--white0);
    display: flex;
    flex-direction: column;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    margin: 0;
    padding: 0;
    overflow: auto;
    max-height: calc(40px * 5);
}

.search .list li {
    text-align: left;
    padding: 4px 16px;
    height: 32px;
    min-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.search .list li:hover {
    background-color: var(--white1);
    cursor: pointer;
}

.search .list li.selected {
    outline: 2px dotted var(--white2);
    outline-offset: -2px;
}

.search .list hr {
    width: 100%;
    color: var(--white1);
    margin: 0;
}

@media screen and (max-width: 488px) {
    .search {
        width: 100%;
    }
}
</style>