<template>

    <ShareDialog :link="'https://mars.divtec.ch/user/' + this.sidePanelInfos.id"
                 :base-name="this.sidePanelInfos.base.nom" v-if="this.$store.state.sidePanel.sharePopupState"/>

    <!-- Side Pannel -->
    <div class="side-panel" :style="{transform: this.sidePanelStates.state ? 'translateX(0)' : 'translateX(-100%)'}">

        <div class="broken_effect" v-if="Object.entries(this.sidePanelInfos).length !== 0 && this.sidePanelInfos.base.date_fin !== null" ></div>

        <div class="side-panel-wrapper" v-if="Object.entries(this.sidePanelInfos).length !== 0" :class="{dead: this.sidePanelInfos.base.date_fin !== null}">
            <div class="header">
                <div class="user-info">
                    <div class="base-name"><i filled v-if="sidePanelInfos.base.date_fin !== null">public</i><span>{{ this.sidePanelInfos.base.nom }}</span></div>
                </div>
                <div class="controls">
                    <button class="classement" @click="this.$router.push('/leaderboard')">
                        <i>workspace_premium</i>
                    </button>
                    <button class="share" @click="this.$store.commit('openSharePopup')">
                        <i>share</i>
                    </button>
                    <button class="close" @click="this.$store.commit('closeSidePanel')">
                        <i>close</i>
                    </button>
                </div>
            </div>

            <div class="main-wrapper">
                <div class="main">
                    <lifetime :user-infos="this.sidePanelInfos" />

                    <Credits :user-infos="this.sidePanelInfos" />

                    <Oxygene :user-infos="this.sidePanelInfos"/>

                    <Onglets primary-title="Description" secondary-title="Badges">
                        <template #primary>
                            <Description :html="this.sidePanelInfos.website" />
                        </template>
                        <template #secondary>
                            <Badges :user-infos="sidePanelInfos"/>
                        </template>
                    </Onglets>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ShareDialog from "@/components/SidePanel/ShareDialog";
import Lifetime from "@/components/SidePanel/Lifetime";
import Credits from "@/components/SidePanel/Credits";
import Oxygene from "@/components/SidePanel/Oxygene";
import Onglets from "@/components/SidePanel/Onglets";
import Description from "@/components/SidePanel/Description";
import Badges from "@/components/SidePanel/Badges";

export default {
    name: "SidePannel",

    components: {
        ShareDialog,
        Lifetime,
        Credits,
        Oxygene,
        Onglets,
        Description,
        Badges
    },

    data() {
        return {
            panelState: false,
            panelDisplay: false,
            now: new Date(),
            interval: null,
        }
    },

    computed: {

        sidePanelStates() {
            return JSON.parse(JSON.stringify(this.$store.state.sidePanel))
        },

        width() {
            console.log(this.$refs.header)
            return this.$refs.header
        },

        sidePanelInfos() {
            return JSON.parse(JSON.stringify(this.$store.state.sidePanel.infos))
        },
    },

    methods: {
        closePannel: function () {
            document.getElementById('lateral-panel-bloc').style.transform = 'translateX(-100%)';
        },
    },

    mounted() {
        this.$store.dispatch('setSidePanelInfos', this.$store.state.activeUser.id)

        let that = this
        this.interval = setInterval(function () {
            if (that.$store.state.activeUser.id !== undefined) {
                that.$store.dispatch('setSidePanelInfos', that.$store.state.activeUser.id)
                that.$store.commit('setBtnOxygenState', false)
            }
        }, 10000)
    },

    unmounted() {
        clearInterval(this.interval)
    }
}

</script>

<style scoped>

.side-panel {
    position: absolute;
    z-index: 2000;
    left: 0;
    height: 100vh;
    width: var(--side-panel-width);
    background-color: var(--white0);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: var(--transition);
}

.side-panel-wrapper.dead {
    filter: grayscale(.25);
}

.side-panel-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--white1);
}

.side-panel .header {
    padding: 8px 10px 12px 10px;
    height: 60px;
    background-color: var(--white1);
}

.side-panel .main-wrapper {
    position: relative;
    overflow-y: scroll;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.side-panel .main {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
    background-color: var(--white0);
    flex: 1;
}

.side-panel .main > div {
    margin-bottom: 16px;
}

.side-panel .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.side-panel .header .user-info {
    display: flex;
    flex-direction: column;
}

.side-panel .header .base-name {
    font-size: 1.5em;
    display: flex;
    align-items: center;
}

.side-panel .header .base-name i{
    font-size: 1.3em;
    margin-right: 8px;
}


.side-panel .header .controls {
    display: flex;
    gap: 16px;
}

.side-panel .header .controls button {
    background-color: var(--white1);
}

.side-panel .header .controls button:hover {
    background-color: var(--white2);
}

@media screen and (max-width: 500px) {
    .side-panel {
        width: 100%;
    }
}

.broken_effect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200000;
    pointer-events: none;
    opacity: .30;
    background-color: rgba(255, 255, 255, .75);
}

.classement, .close, .share {
    position: relative;
}

.classement:after {
    content: "Classement"
}

.share:after {
    content: "Partager"
}

.close:after {
    content: "Fermer"
}

.classement:after, .close:after, .share:after {
    position: absolute;
    top: 40px;
    width: auto;
    height: auto;
    visibility: hidden;
}

.classement:hover:after, .close:hover:after, .share:hover:after {
    visibility: visible;
}

</style>