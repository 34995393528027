<template>

    <div class="disconnect-confirm" v-if="this.disconnectConfirmState">
        <div class="confirm">
            <span>Êtes-vous sur de vouloir vous déconnecter ?</span>
            <div class="buttons">
                <button class="cancel" @click="closeDisconnectConfirm">Annuler</button>
                <button class="logout" @click="logout">Se déconnecter</button>
            </div>
        </div>
    </div>

    <div class="user-controls">
        <button class="menu-btn" @click="openCloseMenu"><i>menu</i></button>
        <div class="menu">
            <div class="wrapper" :style="{transform: this.menuState ? 'translateX(0)'  :'translateX(calc(100% + 16px))'}">
                <button class="stage" @click="goToStage">
                    S'inscrire à un stage
                    <i>badge</i>
                </button>
                <button class="classement" @click="goToLeaderboard">
                    Classement
                    <i>workspace_premium</i>
                </button>
                <button class="logout" @click="openDisconnectConfirm" v-if="userdata !== null">
                    Se déconnecter
                    <i>logout</i>
                </button>
                <button class="emailwait" @click="login" v-else>
                    Se connecter
                    <i>person</i>
                </button>
            </div>
        </div>


        <div class="normal">
            <button class="stage" @click="goToStage">
                S'inscrire à un stage
                <i>badge</i>
            </button>

            <button class="logout" @click="openDisconnectConfirm" v-if="userdata !== null">
                Se déconnecter
                <i>logout</i>
            </button>
            <button class="login" @click="login" v-else>
                Se connecter
                <i>person</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserControls",

    data() {
        return {
            menuState: false,
            disconnectConfirmState: false
        }
    },

    computed: {
        userdata() {
            return JSON.parse(localStorage.getItem('user'))
        },
    },

    methods: {

        openCloseMenu() {
            this.menuState = !this.menuState;
        },

        logout() {
            this.$store.dispatch("logout")
        },

        login() {
            this.$router.push("/login")
        },

        goToStage() {
            this.$store.commit('setStagePopupState', true);
        },

        goToLeaderboard() {
            this.$router.push('/leaderboard')
        },

        openDisconnectConfirm() {
            this.disconnectConfirmState = true
        },

        closeDisconnectConfirm() {
            this.disconnectConfirmState = false
        }
    }
}
</script>

<style scoped>

.disconnect-confirm  {
    z-index: 2000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--black-overlay);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.confirm {
    background-color: var(--white0);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 32px;
    border-radius: var(--border-radius);
    width: 100%;
    max-width: 400px;
    margin: 16px;
}

.confirm span {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    margin: 16px 16px 0 16px
}

.confirm .buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 16px 16px 16px
}

.confirm .buttons button {
    flex: 1;
    font-weight: bold;
}

.confirm .buttons .logout {
    background-color: var(--red1);
    color: var(--white0)
}

.confirm .buttons .logout:hover {
    background-color: var(--red2);
}

.user-controls {
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

button {
    font-size: 1em;
    padding: 0 4px 0 16px;
    width: 210px;
    box-shadow: var(--shadow);
    pointer-events: visible;
}

.menu-btn {
    width: 40px;
    padding: 0;
    visibility: hidden;
    position: absolute;
    top: 16px;
    right: 16px;
}

.normal {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    gap: 16px;
}

.menu button {
    border-radius: 0;
    justify-content: space-between;
}

.menu {
    visibility: hidden;
    z-index: 90000;
    position: absolute;
    top: calc(16px + 40px + 16px);
    right: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    border-radius: var(--border-radius);
    pointer-events: none;
}

.menu .wrapper {
    transition: var(--transition);
}

i {
    color: var(--black0);
    font-size: 1.5em;
}

@media screen and (max-width: 400px) {
    .confirm .buttons {
        flex-direction: column-reverse;
    }

    .confirm .buttons button {
        flex: unset;
        width: 100%;
    }
}

@media screen and (max-width: 940px) {
    .normal .stage, .normal .logout, .normal .login {
        visibility: hidden;
    }

    .menu, .menu-btn {
        visibility: visible;
    }
}

</style>