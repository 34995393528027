import {createStore} from 'vuex'
import axios from "axios";
import router from "@/router";
import {toRaw} from "vue";

axios.defaults.baseURL = "https://api-mars.divtec.ch/api"

export default createStore({
    state: {
        allUsers: [],
        activeUser: {},

        connectionPopupState: false,

        stagePopupState: false,

        sidePanel: {
            state: false,
            sharePopupState: false,
            infos: {},
            isOxygenBtnCharging: false,
        },

        toaster: {
            visible: false,
            type: "",
            message: "",
            timeout: null,
        },
    },

    //Données calculées du magasin
    getters: {
        isLogged(state) {
            return window.localStorage.getItem('user')
        },

        loggedUser(state) {
            return JSON.parse(window.localStorage.getItem('user'))
        }
    },


    mutations: {

        setInfosUsers(state, usersInfos) {
            state.allUsers = usersInfos.filter(user => user.base !== null);
        },


        setActiveUser(state, idUser) {
            if (state.allUsers.filter(user => user.id === idUser)[0] !== undefined) {
                state.activeUser = {}
                setTimeout(function () {
                    state.activeUser = state.allUsers.filter(user => user.id === idUser)[0]
                }, 10)
            }
        },

        toast(state, settings) {
            clearTimeout(state.toaster.timeout)
            state.toaster.visible = false
            state.toaster.type = settings.type;
            state.toaster.message = settings.message;

            state.toaster.visible = true
            state.toaster.timeout = setTimeout(function () {
                state.toaster.visible = false
            }, settings.duration !== undefined ? settings.duration : 2000)
        },

        closeToast(state) {
            clearTimeout(state.toaster.timeout)
            state.toaster.visible = false
        },


        /**************************************************
         * SIDE PANEL
         **************************************************/
        openSidePanel(state) {
            state.sidePanel.state = true;
        },

        closeSidePanel(state) {
            state.sidePanel.state = false;
        },

        openSharePopup(state) {
            state.sidePanel.sharePopupState = true;
        },

        closeSharePopup(state) {
            state.sidePanel.sharePopupState = false;
        },

        setSidePanelInfos(state, infos) {
            state.sidePanel.infos = infos
        },

        setBtnOxygenState(state, btnState) {
            state.sidePanel.isOxygenBtnCharging = btnState
        },

        /**************************************************
         * LOGIN
         **************************************************/
        setLogin(state, userData) {
            localStorage.setItem('user', JSON.stringify(userData))
        },

        clearUserData() {
            localStorage.removeItem('user')
            location.reload()
        },

        setConnectionPopupState(state, popupstate) {
            state.connectionPopupState = popupstate
        },

        setStagePopupState(state, popupState) {
            state.stagePopupState = popupState;
        }
    },
    actions: {
        getUsersInfos({commit}) {
            return axios
                .get('/users')
                .then(response => {
                    commit('setInfosUsers', response.data)
                })
        },

        login({commit}, credentials) {
            return axios
                .post('/login', {
                    email: credentials.email,
                    password: credentials.password
                })
                .then(response => {
                    commit('setLogin', response.data)
                    commit('toast', {
                        type: "success",
                        message: `Bienvenue ${response.data.user.username}`,
                        duration: 5000
                    })

                    if(router.currentRoute._value.name === 'carte') {
                        window.location.reload()
                    } else {
                        router.push('/')
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        commit('toast', {
                            type: "alert",
                            message: "Adresse mail ou mot de passe invalide !",
                            duration: 5000
                        })
                    }
                })
        },

        logout({commit}) {
            commit('clearUserData')
        },


        setSidePanelInfos({commit}, idUser) {
            return axios
                .get('/users/' + idUser)
                .then(response => {
                    commit('setSidePanelInfos', response.data)
                })
        },

        buyOxygen({commit}) {
            var config = {
                method: 'post',
                url: '/bases/oxygen/buy',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('user')).access_token}`
                }
            };

            axios(config)
                .then(function (response) {
                    commit('toast', {
                        type: "success",
                        message: "Oxygène réapprovisionné avec succès",
                        duration: 1000
                    })
                })
                .catch(function (error) {
                    commit('toast', {
                        type: "alert",
                        message: error.response.data.message,
                        duration: 1000
                    })
                });

        },

        resetPassSend({commit}, mail) {
            return axios
                .post('/reset/send', {
                    email: mail
                })
                .then(response => {
                    router.push("/mailwait")
                })
                .catch(function (error) {
                    commit('toast', {
                        type: "alert",
                        message: "Adresse mail invalide !",
                        duration: 1000
                    })

                })
        },

        resetPass({commit}, params) {
            return axios
                .post('/reset/change-password', {
                    resetToken: params.resetToken,
                    email: params.email,
                    password: params.password
                })
                .then(response => {
                    router.push('/login')
                })
                .catch(error => {
                    commit('toast', {
                        type: 'alert',
                        message: 'Une erreur s\'est produite... Vérifiez votre adresse mail ou recommencez la procédure de réinitialisation',
                        duration: 5000,
                    })
                })

        }
    },
})
