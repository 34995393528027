<template>
    <div class="map-controls">
        <button class="back-to-base" @click="backToBase" v-if="this.$store.getters.isLogged">
            <i>my_location</i>
        </button>

        <div class="zoom-controls">
            <button class="zoomin" @click="this.zoomin" :class="{btnDis: this.maxZoom}">
                <i>add</i>
            </button>
            <button class="zoomout" @click="this.zoomout" :class="{btnDis: this.minZoom}">
                <i>remove</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MapControls",

    props: {
        minZoom: {
            type: Boolean,
            required: true,
        },
        maxZoom: {
            type: Boolean,
            required: true,
        }
    },

    methods: {
        backToBase() {
            this.$store.commit('setActiveUser', JSON.parse(window.localStorage.getItem('user')).user.id)
        },

        zoomin() {
            if(!this.maxZoom) {
                this.$emit('zoomin')
            }
        },

        zoomout() {
            if(!this.minZoom) {
                this.$emit('zoomout')
            }
        },
    },
}
</script>

<style scoped>
.map-controls {
    position: absolute;
    z-index: 3000;
    bottom: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.zoom-controls .zoomin{
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.zoom-controls .zoomout{
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.btnDis {
    background-color: var(--white1);
    cursor: default;
}
</style>