<template>
    <div class="lifetime" v-if="!this.leaderboard">
        <div class="wrapper">
            <span class="title">Durée de vie de la base :</span>
            <div class="timer">
                <div class="block">
                    <span class="digit">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).days }}</span>
                    <span class="label">jours</span>
                </div>
                <div class="block">
                    <span class="digit">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).hours }}</span>
                    <span class="label">heures</span>
                </div>
                <div class="block">
                    <span class="digit">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).minutes }}</span>
                    <span class="label">minutes</span>
                </div>
                <div class="block">
                    <span class="digit">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).secondes }}</span>
                    <span class="label">secondes</span>
                </div>
            </div>
        </div>
        <div class="steps">
            <div class="atterissage">
                <i>rocket</i>
                <span class="creation-date">Atterrissage sur Mars le {{ this.getDate(this.userInfos.created_at) }} à {{ this.getHour(this.userInfos.created_at) }}</span>
            </div>
            <div class="retour" v-if="userInfos.base.date_fin !== null">
                <i filled>public</i>
                <span class="creation-date">Retour sur terre le {{ this.getDate(this.goodformat(this.userInfos.base.date_fin)) }} à {{ this.getHour(this.goodformat(this.userInfos.base.date_fin)) }}</span>
            </div>
        </div>
    </div>

    <div class="lifetime-min" v-else>
        <div class="block">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).days }}j</div>
        <div class="block">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).hours }}h</div>
        <div class="block">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).minutes }}m</div>
        <div class="block">{{ this.lifetime(this.userInfos.created_at, this.goodformat(this.userInfos.base.date_fin)).secondes }}s</div>
    </div>
</template>

<script>
export default {
    name: "Lifetime",

    data() {
        return {
            now: new Date(),
            interval: null,
        }
    },

    props: {
        userInfos: {
            required: true
        },

        leaderboard: {
            required: false,
            type: Boolean,
        }
    },

    computed: {
        creationDate() {
            console.log(new Date(this.userInfos.base.date_fin.replace(' ', 'T') + '.000000Z'))
            console.log(new Date(this.userInfos.created_at))
        }
    },

    methods: {
        format2Digit(num) {
            return num > 9 ? num : "0" + num;
        },

        convertDate(rawDate) {
            return new Date(rawDate);
        },

        goodformat(date) {
            if(date !== null) {
                return date.replace(' ', 'T') + '.000000Z'
            } else {
                return null
            }
        },

        lifetime(rawCreationDate, dateMort) {

            let creationDate = new Date(rawCreationDate)

            let diffMillisecondes = Math.abs(creationDate.getTime() - this.now)

            if(dateMort === null) {
                diffMillisecondes = Math.abs(creationDate.getTime() - this.now)
            } else {
                dateMort = new Date(dateMort)
                diffMillisecondes = Math.abs(creationDate.getTime() - dateMort)
            }


            let countdown = {
                days: this.format2Digit(Math.floor(diffMillisecondes / (1000 * 60 * 60 * 24))),
                hours: this.format2Digit(Math.floor((diffMillisecondes / 1000 / 60 / 60) % 24)),
                minutes: this.format2Digit(Math.floor((diffMillisecondes / 1000 / 60) % 60)),
                secondes: this.format2Digit(Math.floor((diffMillisecondes / 1000) % 60))
            }

            return countdown
        },

        getDate(date) {

            let fDate = new Date(date);

            return this.format2Digit(fDate.getDate()) + "." +
                this.format2Digit(fDate.getMonth() + 1) + "." +
                fDate.getFullYear()
        },

        getHour(date) {
            let fDate = new Date(date);
            return this.format2Digit(fDate.getHours()) + "h" + this.format2Digit(fDate.getMinutes())
        },
    },

    mounted() {

         let that = this
        this.interval = setInterval(() => {
            this.now = new Date()
        }, 500);
    },

    unmounted() {
        clearInterval(this.interval)
    }
}
</script>

<style scoped>

.lifetime {
    border-radius: var(--border-radius);
    padding: 16px;
    outline: 2px solid var(--white1);
}

.wrapper {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 0;
    margin: 0 auto;
}

.wrapper .title {
    font-weight: bold;
}

.wrapper .timer {
    font-size: 2em;
    font-weight: bold;
    display: flex;
    width: 100%;
    margin: 4px 0;
    padding: 4px 0;
    border-top: var(--black0) 2px solid;
    border-bottom: var(--black0) 2px solid;
}

.wrapper .timer .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
}

.wrapper .timer .block .digit {
    user-select: none;
}

.wrapper .timer .block .label {
    font-size: .5em;
    font-weight: normal;
    margin-top: -6px;
}

.wrapper .creation-date {
    font-size: .9em;
    font-style: italic;
}

.steps {
    margin-top: 16px;
}

.steps div{
    display: flex;
    align-items: center;
    justify-content: stretch;
}

.steps div i {

}

.steps div .ligne {
    width: 100%;
    height: 0;
    border-top: 2px solid var(--black0);
    transform: translateY(1px);
    padding: 0;
    margin: 0 8px 0 3px;
    opacity: .75;
}

.steps div span {
    width: 100%;
}

.lifetime-min {
    display: flex;
    align-items: stretch;
    gap: 8px;
    font-size: 1.25em;
}

.lifetime-min .block {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>