<template>

    <LoginPopup v-show="this.$store.state.connectionPopupState" />

    <StagePopup v-if="this.$store.state.stagePopupState" />

    <SidePanel />

    <UserControls />

    <SearchControls />

    <div class="logo-divtec">
        <img :src="require('@/assets/dt.png')" alt="Logo de la DivTec">
    </div>

    <Map></Map>
</template>

<script>

import Map from "@/components/Map/Map";
import SidePanel from "@/components/SidePanel/SidePanel";
import SearchControls from "@/components/SearchControls/SearchControls";
import UserControls from "@/components/UserControls";
import LoginPopup from "@/components/Login/LoginPopup";
import StagePopup from "@/components/StagePopup";

export default {
    name: 'CarteView',

    components: {
        LoginPopup,
        Map,
        SidePanel,
        SearchControls,
        UserControls,
        StagePopup
    }
}
</script>

<style>
.logo-divtec {
    z-index: 20000;
    position: absolute;
    bottom: 16px;
    left: 16px;
    background-color: var(--white0);
    padding: 16px 16px 12px 16px;
    border-radius: var(--border-radius);
}

.logo-divtec img {
    height: 32px;
}
</style>
