<template>
    <div class="back">
        <div class="login-popup">
            <Login />
        </div>
    </div>
</template>

<script>

import Login from "@/components/Login";

export default {
    name: "LoginPopup",

    components: {
        Login,
    },
}
</script>

<style scoped>
.back {
    z-index: 3000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.35);
}
</style>