<template>
    <Toaster :message="this.$store.state.toaster.message"
             :visible="this.$store.state.toaster.visible"
             :type="this.$store.state.toaster.type" />
    <router-view />
</template>

<script>

import Toaster from "@/components/Toaster";

export default {
    name: 'App',

    components: {
        Toaster
    },

    mounted: function () {
    }
}
</script>

<style lang="scss">

:root {
    //Palette de couleur
    --honey0: #FFC547;
    --honey1: #FDAD00;
    --honey2: #CC8B00;

    --sunglow0: #FFDA47;
    --sunglow1: #FFCC00;
    --sunglow2: #CCA300;

    --champagne0: #FFFBEB;
    --champagne1: #FFEEAA;
    --champagne2: #FFE270;

    --orange0: #FF9147;
    --orange1: #FF6600;
    --orange2: #CC5200;
    --orange3: #803300;
    --orange4: #522100;

    --purple0: #A30083;
    --purple1: #640050;

    --brown0: #B66454;
    --brown1: #8D4A3D;
    --brown2: #64342B;

    --white0: #FFFFFF;
    --white1: #ECECEC;
    --white2: #D6D6D6;

    --black0: #333333;
    --black1: #1F1F1F;
    --black2: #050505;

    --black-overlay: rgba(0, 0, 0, 0.5);

    --red0: #EA5D5D;
    --red1: #E22222;
    --red2: #B51717;

    --green0: #79B159;
    --green1: #5C8B41;
    --green2: #41612E;

    --map: #aa582d;

    --border-radius: 6px;

    --transition: all ease-in-out 200ms;

    --shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    --side-panel-width: 500px;

    --marker-size: 60px;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--black1);
}

body {
    margin: 0;
}

body * {
    font-family: 'Chakra Petch', sans-serif;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: black;;

        &.router-link-exact-active {
            color: blue;
        }
    }
}

.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.cluster {
    box-sizing: border-box;
    background-color: var(--brown1);
    color: var(--white1);
    text-align: center;
    line-height: 40px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 40px;
    offset-anchor: center;
    width: 40px;
    height: 40px;
    transform: translate(-20px, -20px);
}

i {
    font-family: 'Material Symbols Outlined', sans-serif;
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    font-style: normal;
    display: inline-block;
    margin: 0;
    padding: 3px;
    user-select: none;
    text-decoration: none;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 2em;
}

i[filled] {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

button {
    border:none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    background-color: var(--white0)
}

button:hover {
    cursor: pointer;
    background-color: var(--white1);
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    display: none;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--white2) var(--white1);
}

*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: var(--white1);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--white2);
    border-radius: 20px;
    border: 3px solid var(--white1);
}

.icon-base-1, .icon-base-2, .icon-base-3 {
    width: 20px;
    height: 20px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.icon-base-1:after, .icon-base-2:after, .icon-base-3:after {
    content: "";
    position: absolute;
    width: var(--marker-size);
    height: var(--marker-size);
    background-size: calc(var(--marker-size) / 2);
    background-repeat: no-repeat;
    background-position: center;
    transition: all 1000ms ease-in-out;
}

.icon-base-1:after {
    background-image: url("../src/assets/bases/base_1.png");
}

.icon-base-2:after {
    background-image: url("../src/assets/bases/base_2.png");
}

.icon-base-3:after {
    background-image: url("../src/assets/bases/base_3.png");
}

.userBase:before {
    content: "";
    position: absolute;
    width: calc(var(--marker-size) / 2);
    height: calc(var(--marker-size) / 2);
    border-radius: 100%;
    animation: blink .8s ease-in-out infinite;
}

.dead:after {
    filter: grayscale(1);
}


@keyframes blink {
    0% {
        border: 0px solid rgba(255, 255, 255, 0.75);
    }

    60% {
        border: 6px solid rgba(255, 255, 255, 0.75);
    }

    70% {
        border: 0px solid rgba(255, 255, 255, 0.75);
    }

    100% {
        border: 0px solid var(--white0);
    }
}


</style>
