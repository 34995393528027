<template>
    <div class="onglets">
        <div class="onglets-btn">
            <div class="btn-primary"
                 @click="changeOnglet('primary')"
                 :class="{current: this.activeOnglet === 'primary'}">{{ this.primaryTitle }}
            </div>
            <div class="btn-secondary"
                 @click="changeOnglet('secondary')"
                 :class="{current: this.activeOnglet === 'secondary'}">{{ this.secondaryTitle }}
            </div>
        </div>

        <div class="onglets-main">
            <slot v-if="activeOnglet === 'primary'" name="primary"></slot>
            <slot v-else-if="activeOnglet === 'secondary'" name="secondary"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Onglets",

    props: {
        primaryTitle: {
            required: true,
            type: String,
        },

        secondaryTitle: {
            required: true,
            type: String,
        }
    },

    data() {
        return {
            activeOnglet: "primary",
        }
    },

    methods: {
        changeOnglet(onglet) {
            this.activeOnglet = onglet
        }
    }
}
</script>

<style scoped>

.onglets {
    flex: 1;
    outline: 2px solid var(--white1);
    border-radius: var(--border-radius);
}

.onglets.section {
    padding: 0;
}

.onglets-btn {
    display: flex;
    align-items: stretch;
    height: 32px;
    background-color: var(--white1);
    gap: 8px;
    padding: 8px;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}


.btn-primary, .btn-secondary {
    line-height: 32px;
    padding: 0 8px;
    cursor: pointer;
    position: relative;
    border-radius: var(--border-radius);
    z-index: 10;
}

.btn-primary:hover, .btn-secondary:hover, .btn-primary.current, .btn-secondary.current {
    background-color: var(--white0);
    box-shadow: var(--shadow);
}

.onglets-main {
    padding: 32px;
    overflow: hidden;
}
</style>