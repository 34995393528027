<template>
<div class="search-controls">
    <SearchBar />
    <button class="classement" @click="goToLeaderboard">
        <i>workspace_premium</i>
    </button>
</div>
</template>

<script>

import SearchBar from "@/components/SearchControls/SearchBar";

export default {
    name: "SearchControls",

    components: {
        SearchBar,
    },

    methods: {
        goToLeaderboard() {
            this.$router.push('/leaderboard')
        }
    }
}
</script>

<style scoped>
.search-controls {
    display: flex;
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 1000;
    gap: 16px;
}

.classement:after {
    content: "Classement";
    position: absolute;
    top: 45px;
    width: auto;
    height: auto;
    visibility: hidden;
    padding: 4px 8px;
    background-color: var(--white1);
    border-radius: 100px;
}
.classement:hover:after {
    visibility: visible;
}


@media screen and (max-width: 940px) {
    .classement {
        display: none;
    }
}

@media screen and (max-width: 488px) {
    .search-controls {
        width: calc(100% - 16px - 16px - 40px - 16px);
    }
}
</style>