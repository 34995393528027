import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/CarteView.vue'
import CarteView from "@/views/CarteView";
import store from "@/store";

const routes = [
  {
    path: '/',
    name: 'carte',
    component: CarteView
  },

  {
    path: '/user/:id',
    name: 'user',
    component: CarteView
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },

  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "leaderboard" */ '../views/LeaderBoardView')
  },

  {
    path: '/password-reset/',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../views/PasswordResetView')
  },

  {
    path: '/mailwait',
    name: 'mailwait',
    component: () => import(/* webpackChunkName: "mailwait" */ '../views/MailWaitView')
  },

  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: () => import(/* webpackChunkName: "PageNotFound" */'../views/PageNotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if(to.name === "user") {
    if(to.params.id !== null) {
      store.dispatch('getUsersInfos').then(function () {
        if(store.state.allUsers.filter(user => user.id === Number(to.params.id))[0] !== undefined) {
          store.commit('toast', {
            type: "success",
            message: `Explorateur trouvé !`,
            duration: 2000
          })
          store.commit('setActiveUser', Number(to.params.id))
        } else {
          store.commit('toast', {
            type: "alert",
            message: `Explorateur introuvable...`,
            duration: 2000
          })
        }
      })
    }
    return { name: 'carte' }
  }

  if(to.name === 'carte') {
    let connectionPopup = sessionStorage.getItem('DivtecMarsConnectionPopup')

    if(connectionPopup === null) {

      if(store.getters.isLogged === null) {
        sessionStorage.setItem('DivtecMarsConnectionPopup', "true")
        store.commit('setConnectionPopupState', true)
      } else {
        sessionStorage.setItem('DivtecMarsConnectionPopup', "true")
      }

    }
  }
})

export default router
