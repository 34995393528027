<template>
    <div class="login-wrapper">
        <div class="login">
            <div class="login-form" @keyup.enter="this.login(this.mail, this.password)">
                <form>
                    <ul>
                        <li class="image">
                            <img class="logo" :src="require('/public/Logo.png')" alt="logo de google mars">
                        </li>
                        <li class="title">
                            <h2> Veuillez vous connecter </h2>
                        </li>
                        <li class="input">
                            <label for="user-email">E-mail : </label>
                            <input v-model="mail" id="user-email" type="email" placeholder="E-mail" name="mail" required>
                        </li>
                        <li class="input">
                            <label for="user-pass">Mot de passe : </label>
                            <input v-model="password" id="user-pass" type="password" placeholder="Mot de passe" name="password" required>
                        </li>
                        <li class="buttons">
                            <button class="btn-login" type="button" @click="this.login(this.mail, this.password)">Se connecter</button>
                            <button class="btn-guest" type="button" @click="this.guest">Invité</button>
                        </li>
                        <li class="mdp-reset" @click="passwordReset">Mot de passe oublié ?</li>
                    </ul>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",

    data() {
        return {
            mail: "",
            password: "",
        }
    },

    methods: {
        login(email, password) {
            this.$store.dispatch('login', {
                "email": email,
                "password": password
            })
        },

        guest() {
            this.$router.push('/')
            this.$store.commit('setConnectionPopupState', false)
        },

        passwordReset() {
            if(this.mail.length === 0) {
                this.$store.commit('toast', {
                    type: "alert",
                    message: "Veuillez indiquer votre adresse e-mail",
                    duration: 5000,
                })
            } else {
                this.$store.dispatch('resetPassSend', this.mail)
            }
        }
    }
}
</script>

<style scoped>
.login-wrapper {
    z-index: 20000;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login  {
    text-align: center;
    background-color: var(--black-overlay);
    border-radius: var(--border-radius);
    backdrop-filter: blur(4px);
    padding: 32px;
}

@media screen and (max-width: 400px) {
    .login {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.login-form ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.login-form ul li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.login-form .image img {
    width: 150px;
    aspect-ratio: 1;
}

.login-form .title h2 {
    margin: 0;
    color: var(--white0)
}

.login-form .input {
    justify-content: space-between;
    flex-direction: column;
}

.login-form .input label {
    display: none;
}

.login-form .input input {
    height: 38px;
    border: none;
    border-radius: var(--border-radius);
    padding: 0 8px;
}

.login-form .input input:focus {
    outline: 2px solid var(--orange0)
}

.login-form .buttons {
    gap: 16px;
}

.login-form .buttons button {
    flex: 1;
    font-size: 1.2em;
    font-weight: bold;
    white-space: nowrap;
    width: 150px;
}

.login-form .buttons .btn-login {
    color: var(--white0);
    background-color: var(--green0);
}

.login-form .buttons .btn-login:hover {
    background-color: var(--green1);
}

.login-form .mdp-reset {
    color: var(--white1);
    cursor: pointer;
}

.login-form .mdp-reset:hover {
    text-decoration: underline;
}

</style>