<template>
    <div class="description">
        <div class="desription-panel" v-if="this.html !== null && this.html !== 'empty'"></div>
        <span class="error" v-else>Rendez vous au stand HTML pour créer la description de votre base</span>
    </div>
</template>
<script>

export default {
    name: "description",

    data() {
        return {
            interval: null,
            descriptionPanel: null,
        }
    },

    props: {
        html: {
            required: true,
        }
    },

    methods: {
        setHtml() {
            if (this.html !== null) {
                this.descriptionPanel = document.querySelector('.desription-panel')
                if(this.descriptionPanel !== null) {
                    this.descriptionPanel.innerHTML = this.html.slice(0, this.html.length - 1);
                }
            }
        }
    },

    mounted() {
        this.setHtml()

        let that = this
        this.interval = setInterval(function () {
            that.setHtml()
        }, 1000)
    },

    created() {
    },

    beforeUnmount() {
        clearInterval(this.interval)
    }
}
</script>
<style scoped>


.description {
    display: flex;
    flex-direction: column;
}

.desription-panel {
    user-select: none;
    word-break: break-all;
    white-space: normal;
}

.description .error {
    text-align: center;
}
</style>