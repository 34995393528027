<template>
    <div class="credits">
        <span class="title">Crédit</span>
        <div class="wrapper">
            <span>{{ this.userInfos.base.credit }}</span>
            <div class="mars-coin-icon"
                 :style="{backgroundImage: 'url(\'' + require('@/assets/mars_coin.png') + '\')'}"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Credits",

    props: {
        userInfos: {
            required: true
        }
    },
}
</script>

<style scoped>
.credits {
    border-radius: var(--border-radius);
    padding: 16px;
    outline: 2px solid var(--white1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
}

.credits .wrapper {
    display: flex;
    align-items: center;
}

.credits .wrapper .mars-coin-icon {
    background-size: cover;
    width: 32px;
    height: 32px;
}

.credits .wrapper span {
    font-size: 1.5em;
    margin: 0 8px;
}
</style>