<template>
    <div class="badges">
        <div class="badges-container" v-for="element in this.allBadges"
             :class="{small: element.badges.length <= 3, forced: element.fullwidth === true}">
            <span class="section-title">{{ element.section.substr(0, 4) === 'zzz_' ? element.section.replace(element.section.substr(0, 5), '') : element.section }}</span>
            <div class="badges-list">
                <div class="badge"
                     v-for="badge in element.badges"
                     :class="{active: this.checkBadge(badge.id)}"
                     :style="{backgroundImage: 'url(\'' + require('@/assets/badges/' + badge.image + '.png') + '\')'}">
                    <span class="level" v-show="this.checkBadge(badge.id) && badge.levels">{{ getLvl(this.badgesUser, badge.id) }}</span>
                    <span class="popup">{{ badge.popup }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let badgesJSON = require('/public/badges.json')

export default {
    name: "Badges",

    props: {
        userInfos: {
            required: true
        }
    },

    computed: {
        allBadges() {
            return badgesJSON.allBadges.sort((a, b) => {
                let fa = a.section.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                    fb = b.section.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

                if (fa < fb) {
                    return -1;
                }

                if (fa > fb) {
                    return 1;
                }

                return 0;
            });
        },

        badgesUser() {
            let badges = []
            this.userInfos.badge.forEach(badge => {
                badges.push(badge.id)
            })
            return badges;
        },
    },

    methods: {
        checkBadge(badgeId) {
            if (typeof badgeId === "object") {
                let flags = []
                badgeId.forEach(id => {
                    flags.push(this.badgesUser.indexOf(id) !== -1)
                })

                return flags.indexOf(true) !== -1;
            } else if (typeof badgeId === "number") {
                return this.badgesUser.indexOf(badgeId) !== -1;
            }
        },

        getLvl(userBadgeIds, badgesId) {

            if(typeof badgesId === 'object') {
                let filteredUsersId = []

                for(let i = 0; i < userBadgeIds.length; i++){
                    if(badgesId.indexOf(userBadgeIds[i]) !== -1) {
                        filteredUsersId.push(userBadgeIds[i])
                    }
                }

                filteredUsersId = filteredUsersId.sort((a, b) => {return b-a;})

                let maxId = filteredUsersId[filteredUsersId.length - 1];
                return filteredUsersId.indexOf(maxId) + 1
            }
        },
    }
}
</script>

<style scoped>

.badges {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.badges-container {
    width: 100%;
    border-radius: var(--border-radius);
    outline: 2px solid var(--white1);
}

.section-title {
    border-bottom: 2px solid var(--white1);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.badges-container.small {
    width: calc(50% - 8px);
}

.badges-container.small.forced {
    width: 100%;
}

.badges-container.small .badges-list .badge {
    width: calc(100% / 3);
}

.badges-list {
    display: flex;
    gap: 16px;
    margin: 16px 8px;
    justify-content: center;
}

.badge {
    aspect-ratio: 1;
    width: 60px !important;
    background-size: cover;
    filter: grayscale(1);
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.badge:hover {
    z-index: 2500;
}

.badge.active {
    filter: grayscale(0);
    outline: 2px var(--sunglow0) solid;
    outline-offset: 4px;
}

.badge .popup {
    display: none;
    position: absolute;
    background-color: var(--white0);
    border-radius: 100px;
    box-shadow: var(--shadow);
    padding: 4px 1em;
    width: auto;
    min-width: 90px;
    top: 60px;
    text-align: center;
    text-wrap: none;
    margin: 0 auto;
    justify-content: center;
}
.badge:hover .popup {
    display: flex;
}

.badge .level {
    background: var(--sunglow1);
    border: 2px solid var(--white0);
    color: var(--white0);
    height: 20px;
    width: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 70%;
}

@media screen and (max-width: 475px) {
    .badges-container.small {
        width: 100%;
    }

    .badges-container.small .badges-list .badge {
        width: calc(100% / 5);
    }
}
</style>