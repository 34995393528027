<template>
    <div class="toaster"
         :class="{visible: this.visible}"
         :style="this.getStyle(this.type)"
         @click="this.close">
        <div class="icon"><i filled>{{ this.getIcon(this.type) }}</i></div>
        <div class="message"><span>{{ this.message }}</span></div>
    </div>
</template>

<script>
export default {
    name: "Toaster",

    props: {
        visible: {
            required: true,
            type: Boolean,
        },

        type: {
            required: true,
            type: String,

        },

        message: {
            required: true,
            type: String,
        }
    },

    methods: {
        getStyle(type) {
            switch (type) {
                case 'alert' :
                    return {
                        backgroundColor: 'var(--red1)',
                        color: 'var(--white0)',
                    }
                case 'success' :
                    return {
                        backgroundColor: 'var(--green1)',
                        color: 'var(--white0)'
                    }

                default:
                    return {
                        backgroundColor: 'var(--white0)',
                        color: 'var(--black2)'
                    }
            }
        },

        getIcon(type) {
            switch (type) {
                case 'alert' :
                    return 'report'
                case 'success' :
                    return 'check_circle'
            }
        },

        close() {
            this.$store.commit('closeToast')
        }
    }
}
</script>

<style scoped>
.toaster {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 500000;
    display: flex;
    align-items: stretch;
    justify-content: center;
    transition: var(--transition);
    cursor: pointer;
}

.toaster.visible {
    top: 0;
}

.toaster .message {
    flex: 1;
    padding: 0 8px;
    margin-right: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
}

.toaster .icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toaster .icon i {
    font-size: 3em;
    width: 48px;
    height: 48px;
    line-height: 48px;
}

</style>