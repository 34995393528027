<template>
    <div class="oxygene" v-if="!leaderboard">
        <div class="wrapper">
            <span class="title">Oxygène</span>
            <button class="reload-oxygene-btn"
                    @click="buyOxygen"
                    v-if="this.$store.getters.isLogged &&
                                            this.$store.getters.loggedUser.user.username === this.userInfos.username"
                    :class="{charging: this.$store.state.sidePanel.isOxygenBtnCharging, disable: this.userInfos.base.oxygene === 100 || this.userInfos.base.credit === 0 || this.userInfos.base.date_fin !== null}">
                <span class="btn-left">
                    <span v-if="this.userInfos.base.oxygene < 100 && this.userInfos.base.credit < (100 - this.userInfos.base.oxygene) * 5 && this.userInfos.base.credit > 0">Remplir jusqu'à {{this.oxygenPercent + this.userInfos.base.credit * (1/5)}} %</span>
                    <span v-else>Remplir à 100 %</span>
                </span>

                <span class="btn-separator"></span>

                <span class="btn-right">
                    <span v-if="this.userInfos.base.oxygene < 100 && this.userInfos.base.credit < (100 - this.userInfos.base.oxygene) * 5 && this.userInfos.base.credit > 0">{{this.userInfos.base.credit}}</span>
                    <span v-else>{{(100 - this.userInfos.base.oxygene) * 5}}</span>
                    <span class="mars-coin-icon" :style="{backgroundImage: 'url(\'' + require('@/assets/mars_coin.png') + '\')'}"></span>
                </span>
            </button>
        </div>
        <div class="barre">
            <div class="progression"
                 :style="{
                            width: this.oxygenPercent + '%',
                            backgroundColor: this.oxygenColor,
                         }">
                <span class="oxygene-lvl">{{ this.oxygenPercent }}</span>
            </div>

            <div class="separator-wrapper">
                <span class="separator"></span>
                <span class="separator"></span>
            </div>
        </div>
    </div>

    <div class="oxygene-min" v-else>
        <div class="barre-min">
            <div class="progress-min"
                 :style="{
                            height: this.oxygenPercent + '%',
                            backgroundColor: this.oxygenColor
                        }"></div>
            <div class="oxygenlvl-min">
                <span v-if="this.userInfos.base.date_fin === null">O<sub>2</sub></span>
                <i filled v-else>public</i>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "Oxygene",

    props: {
        userInfos: {
            required: true
        },

        leaderboard: {
            required: false,
            type: Boolean,
        }
    },

    computed: {
        oxygenPercent() {
            let lvlMax = 100;
            return this.userInfos.base.oxygene * 100 / lvlMax
        },

        oxygenColor() {
            if (this.oxygenPercent > (100 / 3 * 2)) {
                return "var(--green0)"
            } else if (this.oxygenPercent > (100 / 3)) {
                return "var(--orange0)"
            } else {
                return "var(--red1)"
            }
        }
    },

    methods: {
        getOxygeneSize(oxygeneLevel) {
            let lvlMax = 100;
            return oxygeneLevel * 100 / lvlMax + "%";
        },

        buyOxygen() {
            if (this.$store.state.sidePanel.isOxygenBtnCharging === false && this.userInfos.base.date_fin === null) {
                this.$store.commit('setBtnOxygenState', true)
                this.$store.dispatch('buyOxygen')
            }
        },
    }
}
</script>

<style scoped>

.oxygene {
    border-radius: var(--border-radius);
    padding: 16px;
    outline: 2px solid var(--white1);
}

.oxygene .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.oxygene .title {
    font-size: 1.5em;
    font-weight: bold;
    height: 40px;
}

.barre {
    position: relative;
    height: 20px;
    border-radius: 20px;
    margin-top: 40px;
    width: 100%;
    background-color: var(--white1);
}

.barre .separator-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
}

.separator {
    width: 2px;
    padding: 0;
    background-color: var(--black0);
    opacity: .2;
}

.barre .progression {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 100px;
}

.barre .progression:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 8px;
    right: 8px;
    opacity: .25;
    height: 5px;
    border-radius: 5px;
    background-color: var(--white0);
}

.oxygene-lvl {
    width: 100%;
    white-space: nowrap;
    text-align: right;
    position: absolute;
    user-select: none;
    bottom: 20px;
    height: 25px;
}

.reload-oxygene-btn {
    width: auto;
    background-color: var(--sunglow0);
    display: flex;
    align-items: stretch;
    padding: 0;
    overflow: hidden;
}

.reload-oxygene-btn .btn-left {
    padding: 0 7px;
}

.reload-oxygene-btn .btn-right {
    padding: 0 7px;
    gap: 4px;
}

.reload-oxygene-btn .btn-left, .reload-oxygene-btn .btn-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
}


.reload-oxygene-btn.charging {
    filter: grayscale(1);
}

.reload-oxygene-btn.disable {
    filter: grayscale(1);
    cursor: not-allowed;
}

.reload-oxygene-btn:hover {
    background-color: var(--sunglow1);
}

.reload-oxygene-btn.charging:hover {
    background-color: var(--white1);
    cursor: progress;
}

.reload-oxygene-btn .btn-separator {
    width: 2px;
    padding: 0;
    background-color: var(--black0);
    opacity: .2;
}

.reload-oxygene-btn i {
    color: var(--black0)
}

.reload-oxygene-btn span {
    font-size: 1.2em;
    color: var(--black0)
}

.reload-oxygene-btn .mars-coin-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}

.oxygene-min {
    margin-left: 16px;
}

.oxygene-min .barre-min {
    border-radius: var(--border-radius);
    background-color: var(--white2);
    height: 100%;
    width: 45px;
    position: relative;
    overflow: hidden;
}

.oxygene-min .barre-min .progress-min {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.oxygene-min .barre-min .oxygenlvl-min {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

@media screen and (max-width: 450px) {
    .wrapper {
        flex-direction: column;
    }
}

</style>