<template>
    <div class="stage">
        <div class="stage-popup">
            <div class="controls">
                <span class="title">Inscription à un stage</span>
                <button class="close" @click="this.$store.commit('setStagePopupState', false)">
                    <i>close</i>
                </button>
            </div>
            <iframe src="https://www.divtec.ch/ecole-des-metiers-techniques/inscription-stage-emt/"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "StagePopup"
}
</script>

<style scoped>
.stage {
    z-index: 20000;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .35);
}

.stage-popup {
    border-radius: var(--border-radius);
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    overflow: hidden;
    background-color: var(--white0);
}

.stage-popup .title {
    font-size: 1.5em;
    font-weight: bold;
}

.stage-popup .controls {
    width: calc(100% - 16px - 16px);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white1);
    height: 40px;
}

.stage-popup .controls button {
    background-color: var(--white1);
}

.stage-popup .controls button:hover {
    background-color: var(--white2);
}

iframe {
    position: absolute;
    left: 0;
    top: calc(40px + 16px + 16px);
    width: 100%;
    height: calc(100% - (40px + 16px + 16px));
    border: none;
}
</style>